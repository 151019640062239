import { ReactNode } from 'react';
import * as DropdownMenuUI from '@radix-ui/react-dropdown-menu';

import styles from './DropdownMenu.module.scss';

interface DropdownMenuProps {
  children: ReactNode;
  items: {
    id: string;
    label: string;
    onClick: () => void;
  }[];
}

const DropdownMenu = ({ children, items }: DropdownMenuProps) => {
  return (
    <DropdownMenuUI.Root modal={false}>
      <DropdownMenuUI.Trigger asChild>
        <button className={styles.btnTrigger} aria-label="Customise options">
          {children}
        </button>
      </DropdownMenuUI.Trigger>

      <DropdownMenuUI.Content
        className={styles.DropdownMenuContent}
        sideOffset={5}
      >
        {items.map((item, key) => (
          <DropdownMenuUI.Item
            key={`dropdown-menu-item-${key}-${item.id}`}
            onSelect={item.onClick}
            className={styles.DropdownMenuItem}
          >
            {item.label}
          </DropdownMenuUI.Item>
        ))}

        <DropdownMenuUI.Separator className={styles.DropdownMenuSeparator} />

        <DropdownMenuUI.Arrow className={styles.DropdownMenuArrow} />
      </DropdownMenuUI.Content>
    </DropdownMenuUI.Root>
  );
};

export default DropdownMenu;
