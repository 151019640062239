import { ActionMap } from '@/utils/contexts';
import { CurrencyTypes } from '@/config/locales';
import { ReactNode } from 'react';

export interface StateProps {
  currency: CurrencyTypes;
}

export enum ActionTypes {
  SetCurrency = 'SET_CURRENCY',
}
export type ActionPayload = {
  [ActionTypes.SetCurrency]: {
    currency: CurrencyTypes;
  };
};

export type ReducerActions =
  ActionMap<ActionPayload>[keyof ActionMap<ActionPayload>];

export interface ContextProps extends StateProps {
  setCurrency: (payload: CurrencyTypes) => void;
}

export interface ProviderProps {
  children: ReactNode;
  currency: CurrencyTypes;
}
