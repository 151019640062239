export const pagesWithBodyBgGray = ['/checkout/*', '/my-orders/*'];

export const pagesWithFixedHeader = ['/search'];

export const pageWithWhiteHeader = [
  '/',
  '/checkout/*',
  '/blog/*',
  '/itineraries',
];
export const pageWithBlackHeader = [
  '/search/*',
  '/blog/post/*',
  '/experiences/*',
];
export const pageWithOrangeHeader = ['/category/*', '/faq/*', '/help-center'];

export const checkIfIsEqual = (pathname: string, item: string): boolean =>
  item === pathname;
export const checkIfHasApart = (pathname: string, item: string): boolean =>
  pathname.includes(item.replace('/*', ''));

export const checkIfHas = (list: string[], pathname = '') => {
  const hasInList = list.some((item) => {
    if (item.includes('*')) return checkIfHasApart(pathname, item);
    return checkIfIsEqual(pathname, item);
  });
  return hasInList;
};
