'use client';

import {
  createContext,
  useMemo,
  useReducer,
  useCallback,
  useContext,
} from 'react';

import { CurrencyTypes } from '@/config/locales';
import { setCurrency as setCurrencyCookie } from '@/app/_actions/currency';

import {
  StateProps,
  ActionTypes,
  ReducerActions,
  ContextProps,
  ProviderProps,
} from './types';

const fetchReducer = (state: StateProps, action: ReducerActions) => {
  switch (action.type) {
    case ActionTypes.SetCurrency:
      return { ...state, currency: action.payload.currency };
    default:
      return state;
  }
};

export const CurrencyContext = createContext<ContextProps | null>(null);

export const CurrencyProvider = ({ children, currency }: ProviderProps) => {
  const initialState: StateProps = { currency: currency ?? 'BRL' };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const setCurrency = useCallback(async (newCurrency: CurrencyTypes) => {
    await setCurrencyCookie(newCurrency);
    dispatch({
      type: ActionTypes.SetCurrency,
      payload: { currency: newCurrency },
    });
  }, []);

  const values = useMemo(
    () => ({ ...state, setCurrency }),
    [state, setCurrency],
  );

  return (
    <CurrencyContext.Provider value={values}>
      {children}
    </CurrencyContext.Provider>
  );
};

export function useCurrency() {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
}
