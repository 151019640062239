export type localesTypes =
  | 'pt-br'
  | 'en'
  | 'fr'
  | 'es'
  | 'es-ar'
  | 'es-cl'
  | 'es-bo'
  | 'pt-pt'
  | 'es-mx';
export const locales: Readonly<localesTypes[]> = [
  'pt-br',
  'en',
  'fr',
  'es',
  'es-ar',
  'es-cl',
  'es-bo',
  'pt-pt',
  'es-mx',
] as const;
export const localeCurrencies: Readonly<{ [key in localesTypes]: string }> = {
  'pt-br': 'BRL',
  en: 'USD',
  fr: 'EUR',
  es: 'EUR',
  'es-ar': 'ARS',
  'es-cl': 'CLP',
  'es-bo': 'BOB',
  'pt-pt': 'EUR',
  'es-mx': 'MXN',
} as const;

export type CurrencyTypes = 'BRL' | 'USD';
export const currencies: Readonly<CurrencyTypes[]> = ['BRL', 'USD'] as const;

export type LanguageTypes =
  | 'pt_BR'
  | 'en_US'
  | 'fr_FR'
  | 'es_MX'
  | 'pt_PT'
  | 'es_ES'
  | 'es_AR'
  | 'es_CL'
  | 'es_BO';
export const languages: Readonly<LanguageTypes[]> = [
  'pt_BR',
  'en_US',
  'fr_FR',
  'es_MX',
  'pt_PT',
  'es_ES',
  'es_AR',
  'es_CL',
  'es_BO',
] as const;
