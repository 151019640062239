import { localesTypes } from '@/config/locales';

export const hasChildren = (data: { children: { data: any[] } }) => {
  if (data.children && data.children.data && data.children.data.length > 0)
    return true;

  return false;
};

export const getTitle = (
  data: { title: string; title_en: string },
  locale: localesTypes,
) => {
  if (locale === 'en') return data.title_en;

  return data.title;
};

export const getHref = (
  href: string,
  href_en: string,
  locale: localesTypes,
) => {
  if (locale === 'en' && href_en !== null) return href_en;

  return href;
};
