'use client';

import { useState, useCallback } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import classNames from 'classnames';
import { useTranslations, useLocale } from 'next-intl';
import { signOut } from 'next-auth/react';

import { useCurrency } from '@/contexts/CurrencyContenxt';
import { VehiclesCategoriesProps } from '@/types';
import { isURL } from '@/utils/url';
import { hasChildren, getTitle, getHref } from '@/utils/menu';
import {
  pagesWithFixedHeader,
  pageWithWhiteHeader,
  pageWithBlackHeader,
  pageWithOrangeHeader,
  checkIfHas,
} from '@/utils/layout';
import { localesTypes } from '@/config/locales';
import { useAuth } from '@/contexts/AuthContext';

import { roboto } from '@/styles/fonts';
import Button, { ButtonBaseProps } from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import VehicleCategoryCard from '@/components/ui/VehicleCategoryCard';
import DropdownMenu from '@/components/ui/DropdownMenu';
import TranslationModal from '@/components/translation/TranslationModal';
import { clearCookie } from '@/app/_actions/clear-cookie';

import styles from './Header.module.scss';

// Theme configuration
const theme = {
  white: {
    'header-class': '',
    'menu-item-class': '',
    logo: '/img/mobility_logo_25.png',
    'login-btn-color': 'primary',
    'i18n-btn-color': 'secondary',
  },
  black: {
    'header-class': 'header--black',
    'menu-item-class': 'menuItem--inverse',
    logo: '/img/mobility-logo.svg',
    'login-btn-color': 'primary-inverse',
    'i18n-btn-color': 'secondary-inverse',
  },
  orange: {
    'header-class': 'header--orange',
    'menu-item-class': '',
    logo: '/img/mobility-logo-black.svg',
    'login-btn-color': 'secondary',
    'i18n-btn-color': 'secondary',
  },
};

// Types
interface strapiMenuItemProps {
  order: number;
  title: string;
  url: string;
  url_en: string;
  target: '_blank' | '_self' | '_parent' | '_top' | null;
  title_en: string;
  action_button: boolean | null;
  is_vehicles_categories_menu: boolean | null;
  vehicles_categories?: {
    data: VehiclesCategoriesProps[];
  };
  children: {
    data: {
      id: number;
      attributes: strapiMenuItemProps;
    }[];
  };
}

interface strapiMenuProps {
  title: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  items: {
    data: {
      id: string;
      attributes: strapiMenuItemProps;
    }[];
  };
}

interface strapiMenuResponseProps {
  id: string;
  attributes: strapiMenuProps;
}

// Helper functions
const getTheme = (pathname: string) => {
  const isBlackHeader = checkIfHas(pageWithBlackHeader, pathname);
  const isOrangeHeader =
    !isBlackHeader && checkIfHas(pageWithOrangeHeader, pathname);
  const isWhiteHeader =
    !isBlackHeader &&
    !isOrangeHeader &&
    checkIfHas(pageWithWhiteHeader, pathname);

  if (isBlackHeader) return theme.black;
  if (isOrangeHeader) return theme.orange;
  if (isWhiteHeader) return theme.white;
  return theme.white;
};

// Main component
const Header = ({
  menuData,
  userType,
}: {
  menuData: strapiMenuResponseProps;
  userType: string;
}) => {
  const pathname = usePathname();
  const locale = useLocale() as localesTypes;
  const router = useRouter();
  const [openModalLanguage, setOpenModalLanguage] = useState(false);
  const [mobileMenuState, setMobileMenuState] = useState<{
    open: boolean;
    itemOpen: string;
  }>({
    open: false,
    itemOpen: '',
  });

  // Centralized cookie clearing & navigation function
  const handleNavigation = useCallback(
    async (
      e: React.MouseEvent<HTMLElement>,
      href: string,
      clearUserType = false,
    ) => {
      e.preventDefault();

      // Only clear cookie if specifically requested
      if (clearUserType) {
        await clearCookie('user-type');
      }

      // Use Next.js router for all navigation
      router.push(href);
    },
    [router],
  );

  const shouldClearCookie = useCallback((title: string) => {
    const lowerTitle = title.toLowerCase();
    return lowerTitle === 'página inicial' || lowerTitle === 'buscar veículos';
  }, []);

  const handleToggleMenuItem = useCallback((itemId: string) => {
    setMobileMenuState((prev) => ({
      ...prev,
      itemOpen: prev.itemOpen === itemId ? '' : itemId,
    }));
  }, []);

  // Process menu items
  const menuItems = menuData.attributes.items.data
    .filter((item) => item.attributes.title !== 'Produtos')
    .map((item) => ({
      ...item,
      attributes: {
        ...item.attributes,
        children: {
          ...item.attributes.children,
          data: item.attributes.children.data.filter((child) => child.id !== 6),
        },
      },
    }));

  // Class names
  const isFixedHeader = checkIfHas(pagesWithFixedHeader, pathname);
  const headerTheme = getTheme(pathname);
  const headerClasses = classNames([
    styles.header,
    { [`${styles['header--fixed']}`]: isFixedHeader },
    styles[headerTheme['header-class']],
    { [`${styles['header--mobile-open']}`]: mobileMenuState.open },
  ]);
  const menuItemClasses = classNames([
    styles.menuItem,
    styles[headerTheme['menu-item-class']],
  ]);
  const i18nBtnColor = headerTheme['i18n-btn-color'];
  const toggleMenuButtonClasses = classNames([
    styles.toggleMenu,
    styles[`toggleMenu--${i18nBtnColor}`],
  ]);
  const logoUrl = headerTheme.logo;

  // Sub-component: MegaMenuProductItem
  const MegaMenuProductItem = ({ data }: { data: strapiMenuItemProps }) => (
    <>
      {data.vehicles_categories?.data.map((item) => {
        const title =
          locale === 'en' && item.attributes.localizations?.data.length
            ? item.attributes.localizations?.data[0].attributes.name
            : item.attributes.name;
        return (
          <VehicleCategoryCard
            key={`menu-vehicles-category-${item.id}`}
            data={{ ...item, attributes: { ...item.attributes, name: title } }}
          />
        );
      })}
    </>
  );

  // Sub-component: MegaMenuItem
  const MegaMenuItem = ({ data }: { data: strapiMenuItemProps }) => {
    const title = getTitle(
      {
        title: data.title,
        title_en: data.title_en,
      },
      locale,
    );

    return (
      <div className={styles.megaMenuItem}>
        <h4>{title}</h4>
        {hasChildren(data) && (
          <ul>
            {data.children.data
              .filter((item) => item.id !== 15)
              .map((item) => {
                const currHref = item.attributes.url;
                const localeHref = getHref(
                  currHref,
                  item.attributes.url_en,
                  locale,
                );
                const LinkComponent = currHref && isURL(currHref) ? 'a' : Link;
                const isActionButton = Boolean(item.attributes.action_button);
                const subtitle = getTitle(
                  {
                    title: item.attributes.title,
                    title_en: item.attributes.title_en,
                  },
                  locale,
                );
                const needsClearCookie = shouldClearCookie(
                  item.attributes.title,
                );

                const handleClick = (e: React.MouseEvent<HTMLElement>) => {
                  if (needsClearCookie) {
                    handleNavigation(e, localeHref, true);
                  }
                };

                return (
                  <li
                    key={`mega-menu-sub-item-${item.id}`}
                    className={isActionButton ? styles.seeMore : ''}
                  >
                    <LinkComponent
                      href={localeHref}
                      title={subtitle}
                      target={
                        item.attributes.target === '_blank' ? '_blank' : ''
                      }
                      onClick={needsClearCookie ? handleClick : undefined}
                    >
                      {isActionButton ? <span>{subtitle}</span> : subtitle}
                      {isActionButton && (
                        <span className="material-symbols-outlined">
                          add_box
                        </span>
                      )}
                    </LinkComponent>
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    );
  };

  // Sub-component: MegaMenu
  const MegaMenu = ({
    data,
    isCategoriesMenu = false,
    mobileIsOpen = false,
  }: {
    data: strapiMenuItemProps;
    isCategoriesMenu: boolean;
    mobileIsOpen: boolean;
  }) => {
    const t = useTranslations('Header');
    return (
      <div
        className={`${roboto.className} ${styles.megaMenu} ${mobileIsOpen && styles['megaMenu--mobile-open']}`}
      >
        <div className="container">
          <div
            className={`${styles.megaMenuWrapper} ${isCategoriesMenu && styles['megaMenuWrapper--categories']}`}
          >
            {isCategoriesMenu ? (
              <MegaMenuProductItem data={data} />
            ) : (
              <>
                {data.children?.data.map((item) => (
                  <MegaMenuItem
                    key={`mega-menu-item-${item.id}`}
                    data={item.attributes}
                  />
                ))}
              </>
            )}
          </div>

          <div className={styles.megaMenuFooter}>
            <Link href="/">
              {t('help_center')}
              <span className="material-symbols-outlined">help</span>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  // Sub-component: ActionsMenu
  const ActionsMenu = ({ isMobile }: { isMobile?: boolean }) => {
    const t = useTranslations();
    const { currency: currentCurrency } = useCurrency();
    const { openLoginModal, session } = useAuth();
    const headerTheme = getTheme(isMobile ? 'mobile' : pathname);
    const emptyUserType = userType === '';

    const loginBtnColor = headerTheme[
      'login-btn-color'
    ] as ButtonBaseProps['color'];
    const i18nBtnColor = headerTheme[
      'i18n-btn-color'
    ] as ButtonBaseProps['color'];

    const renderLoginButton =
      userType === 'agent' ? (
        <Button
          color="secondary"
          size="small"
          weight="600"
          transform="initial"
          href="https://b2b.mobility.com.br"
          target="_blank"
        >
          {t('Header.login_agent_button')}
        </Button>
      ) : (
        <Button
          color={loginBtnColor}
          size="small"
          weight="600"
          transform="initial"
          onClick={() => openLoginModal(true)}
        >
          {t('Header.login_customer_button')}
        </Button>
      );

    return (
      <div className={styles.actionsMenu}>
        {session && session.user ? (
          <DropdownMenu
            items={[
              {
                id: 'my-profile',
                label: t('Profile.my_account'),
                onClick: () => router.push('/my-account'),
              },
              {
                id: 'my-orders',
                label: t('Profile.my_orders'),
                onClick: () => router.push('/my-orders'),
              },
              {
                id: 'logout',
                label: t('Profile.logout'),
                onClick: () => signOut(),
              },
            ]}
          >
            <span className={styles.userWrapper}>
              <Image
                src={session.user.image}
                alt={session.user.name}
                width={40}
                height={40}
                className={styles['userWrapper__avatar']}
                unoptimized
              />
              <Icon name="expand_more" />
            </span>
          </DropdownMenu>
        ) : (
          !emptyUserType && renderLoginButton
        )}
        <Button
          variant="text"
          size="fit"
          color={i18nBtnColor}
          style={{ paddingLeft: 0, paddingRight: 0, minWidth: 100 }}
          onClick={() => setOpenModalLanguage(true)}
        >
          <Icon name="public" style={{ marginRight: '1rem' }} />{' '}
          {locale.toLocaleUpperCase()} {t(`currency.${currentCurrency}`)}
        </Button>
      </div>
    );
  };

  return (
    <>
      <header className={headerClasses}>
        <div className={styles.logo}>
          <Link
            href="/"
            title="Go to Home"
            onClick={(e) => handleNavigation(e, '/', true)}
          >
            <Image
              src={logoUrl}
              alt="Mobility Logo"
              width={135}
              height={56}
              priority
            />
          </Link>
        </div>

        <nav>
          <ul className={styles.menu}>
            {menuItems.map((item) => {
              const isCategoriesMenu = Boolean(
                item.attributes.is_vehicles_categories_menu,
              );
              const hasMegaMenu =
                hasChildren(item.attributes) || isCategoriesMenu;
              const title = getTitle(
                {
                  title: item.attributes?.title,
                  title_en: item.attributes?.title_en,
                },
                locale,
              );

              const currHref = item.attributes?.url;
              const LinkComponent = currHref && isURL(currHref) ? 'a' : Link;
              const mobileIsOpen = mobileMenuState.itemOpen === item.id;
              const needsClearCookie =
                currHref && shouldClearCookie(item.attributes.title);

              const handleClick = (e: React.MouseEvent<HTMLElement>) => {
                if (needsClearCookie) {
                  handleNavigation(e, currHref, true);
                }
              };

              return (
                <li
                  key={`menu-item-${item.id}`}
                  className={`menu-item ${menuItemClasses} ${
                    hasMegaMenu && styles.hasMegaMenu
                  } ${mobileIsOpen && styles['menuItem--open']}`}
                >
                  {hasMegaMenu ? (
                    <span
                      onClick={() => handleToggleMenuItem(item.id)}
                      className={`text-link ${styles.textLink}`}
                    >
                      {title}
                      <span className="material-symbols-outlined">
                        expand_more
                      </span>
                    </span>
                  ) : (
                    <LinkComponent
                      href={currHref}
                      title={title}
                      className={`text-link ${styles.textLink}`}
                      onClick={needsClearCookie ? handleClick : undefined}
                    >
                      {title}
                    </LinkComponent>
                  )}
                  {hasMegaMenu && (
                    <MegaMenu
                      data={item.attributes}
                      isCategoriesMenu={isCategoriesMenu}
                      mobileIsOpen={mobileIsOpen}
                    />
                  )}
                </li>
              );
            })}
          </ul>

          <div className={styles.actionsMenuMobile}>
            <ActionsMenu isMobile />
          </div>
        </nav>

        <ActionsMenu />

        <button
          type="button"
          className={toggleMenuButtonClasses}
          onClick={() =>
            setMobileMenuState((prev) => ({ itemOpen: '', open: !prev.open }))
          }
        >
          <Icon name={mobileMenuState.open ? 'close' : 'menu'} />
        </button>
      </header>
      <TranslationModal
        open={openModalLanguage}
        onOpenChange={() => setOpenModalLanguage((prev) => !prev)}
      />
    </>
  );
};

export default Header;
