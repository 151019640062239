import Link from 'next/link';
import Image from 'next/image';

import { VehiclesCategoriesProps } from '@/types/vehicles_categories';
import { isURL } from '@/utils/url';

import styles from './VehicleCategoryCard.module.scss';

function VehicleCategoryCard({ data }: { data: VehiclesCategoriesProps }) {
  const LinkEl = isURL(data.attributes.url) ? 'a' : Link;
  const img = data.attributes.image.data.attributes;

  return data ? (
    <LinkEl
      href={data.attributes.url}
      title={data.attributes.name}
      className={`vehicle-category-card ${styles.categoryCard}`}
    >
      <Image
        src={`${process.env.NEXT_PUBLIC_STRAPI_CMS_URL}${img.url}`}
        alt={img.name}
        width={img.width}
        height={img.height}
      />
      <p>{data.attributes.name}</p>
    </LinkEl>
  ) : null;
}

export default VehicleCategoryCard;
