'use client';

import { useEffect, useState } from 'react';
import { useLocale, useTranslations } from 'next-intl';

import { useRouter, usePathname } from '@/navigation';
import { localesTypes, CurrencyTypes } from '@/config/locales';
import { useCurrency } from '@/contexts/CurrencyContenxt';

import Modal, { ModalProps } from '@/components/ui/Modal';

import styles from './TranslationModal.module.scss';
import { useSearch } from '@/contexts/SearchContext';

const tabs = [
  {
    id: 'language',
    label: 'language',
  },
  //{
  //  id: 'currency',
  //  label: 'currency',
  //},
];

interface TabOptionsProps {
  language: {
    id: localesTypes;
    label: string;
  }[];
  currency: {
    id: CurrencyTypes;
    label: string;
  }[];
}
const tabOptions: TabOptionsProps = {
  language: [
    {
      id: 'pt-br',
      label: 'Português(BR)',
    },
    {
      id: 'en',
      label: 'English',
    },
  ],
  currency: [
    {
      id: 'USD',
      label: 'currency.dollar',
    },
    {
      id: 'BRL',
      label: 'currency.real',
    },
  ],
};

interface LoginModalProps extends Omit<ModalProps, 'children'> {}

const TranslationModal = ({ ...rest }: LoginModalProps) => {
  const t = useTranslations();
  const router = useRouter();
  const pathname = usePathname();
  const locale = useLocale();
  const { setCurrency, currency: currentCurrency } = useCurrency();

  const [tab, setTab] = useState(tabs[0]);

  const onChange = async (value: localesTypes | CurrencyTypes) => {
    if (tabId === 'currency') {
      await setCurrency(value as CurrencyTypes);
      router.refresh();

      return;
    }
    router.push(pathname, { locale: value as localesTypes });
  };

  const tabId = tab.id;
  const options =
    tabOptions[tabId as keyof typeof tabOptions] || tabOptions.language;
  const compareItem = tabId === 'currency' ? currentCurrency : locale;

  return (
    <Modal className={styles.modal} {...rest}>
      <h2 className="title">{t('TranslationModal.title')}</h2>

      <div className={styles.container}>
        <div className={styles.content}>
          <h4 className="title title--xsmall">
            {t('TranslationModal.title_select_lang_currency')}
          </h4>
          <ul className={styles.menu}>
            {tabs.map((item) => (
              <li key={`tabs-language-${item.id}`}>
                <button
                  type="button"
                  className={`${styles.button} ${
                    tabId === item.id && styles['button--active']
                  }`}
                  onClick={() => setTab(item)}
                >
                  {t(`common.${item.label}`)}
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.content}>
          <h4 className="title title--xsmall">{t('common.recommended')}</h4>
          <ul className={styles.menu}>
            {options.map((item) => (
              <li key={`tab-options-${tabId}-item-${item.id}`}>
                <button
                  type="button"
                  className={`${styles.button} ${
                    compareItem === item.id && styles['button--active']
                  }`}
                  onClick={() => onChange(item.id)}
                >
                  {tabId === 'currency' ? (
                    <>
                      {t(`${item.label}`)}{' '}
                      <span>{t(`${item.label}_short`)}</span>
                    </>
                  ) : (
                    item.label
                  )}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
};

export default TranslationModal;
